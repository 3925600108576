import { Component, Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import Vue from 'vue';
import $ from 'jquery';
import Axios from 'axios';
import { BASE_URL_MANAGER } from '@/config';

@Component({
  name: 'create-state-modal',
})
export default class CreateStateModal extends VueWizard {
  @Prop() readonly elementId!: string;
  @Prop({ default: true }) readonly autoDisable!: boolean;
  @Prop() readonly parentElementId!: string;
  @Prop() readonly EventBus!: Vue;
  @Prop() readonly projects!: any;

  public colorList: string[] = [
    '#8EA9DB',
    '#B4C6E7',
    '#D9E1F2',
    '#F4B084',
    '#F8CBAD',
    '#FCE4D6',
    '#FFD966',
    '#FFE699',
    '#FFF2CC',
    '#A9D08E',
    '#C6E0B4',
    '#E2EFDA',
  ];

  public selectedColor: string = '';
  public validColor: boolean = true;
  public errorMessage: string = '';
  public validForm: boolean = true;
  public validProject: boolean = true;
  public validName: boolean = true;
  public name: string = '';
  public projectSelected: string = '0';
  public message: any = '';
  public description: any = '';
  public inputField: any = null;
  public finishValue: boolean = false;

  created() {
    this.EventBus.$on('cleanModal', () => {
      this.cleanComponent();
    });
  }

  disable() {
    if (this.autoDisable) {
      $('#' + this.elementId).modal('hide');
    } else {
      this.elementDisableEnable(
        '#' + this.elementId,
        '#' + this.parentElementId,
      );
    }
  }

  elementDisableEnable(elementId: string, enableId: any): void {
    let modal: any = $(elementId);
    modal.modal('hide');
    modal = $(enableId);
    modal.modal('show');
  }

  changeSelectedColor(color: string) {
    this.selectedColor = color;
  }

  verifyColor() {
    if (this.selectedColor !== '') {
      this.validColor = true;
    } else {
      this.validColor = false;
      this.errorMessage =
        'No se ha seleccionado ningún color, seleccione un color para poder continuar';
    }
  }

  verifyProject() {
    if (this.projectSelected !== '0') {
      this.validProject = true;
    } else {
      this.validProject = false;
      this.errorMessage = 'Debe de seleccionar un proyecto para continuar';
    }
  }

  verifyName() {
    const validInputField = this.inputField.value.replace(/\W/g, '');
    if (this.inputField.value !== validInputField) {
      this.validName = false;
      this.errorMessage =
        'El nombre no cumple con las condiciones especificadas';
    } else {
      this.validName = true;
    }
  }

  handleNameInput(event: any) {
    this.inputField = event.target;
    event.target.value = event.target.value.replace(/\W/g, '');
    event.target.value = event.target.value.toUpperCase();
    this.name = event.target.value;
  }

  handleUpload() {
    this.verifyColor();
    this.verifyProject();
    this.verifyName();

    this.validForm = this.validColor && this.validProject && this.validName;

    if (this.validForm) {
      this.uploadInfo();
    }
  }

  async uploadInfo() {
    const url = `${BASE_URL_MANAGER}/project/${this.projectSelected}/bussinesstatus`;
    const data = {
      name: this.name,
      description: this.description,
      color: this.selectedColor,
      message: this.message,
      finish: this.finishValue,
    };

    try {
      const res = await Axios.post(url, data);
      this.disable();
      this.cleanComponent();

      if (res.data.saved) {
        this.EventBus.$emit('reloadList');
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Estado guardado exitosamente!',
          description: 'Los datos del estado han sido guardados',
        });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al guardar al información`,
          description: `Error`,
        });
      }
    } catch (err) {
      this.$notification.error({
        placement: 'bottomRight',
        message: `Hubo un error al guardar al información`,
        description:
          'Hubo un error al guardar la información, por favor verifica que no haya otro estado con ese mismo nombre en el proyecto escogido',
      });
    }
  }
  cleanComponent() {
    this.selectedColor = '';
    this.validColor = true;
    this.errorMessage = '';
    this.validForm = true;
    this.validProject = true;
    this.name = '';
    this.projectSelected = '0';
    this.message = '';
    this.description = '';
    this.inputField.value = '';
    this.inputField = null;
    this.finishValue = false;
  }
}
