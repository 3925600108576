import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';

import BussinesStatusCard from '@/app/components/bussiness-states-management/dedicated-components/bussines-status-card/BussinesStatusCard.vue';

@Component({ name: 'bussines-card-deck', components: { BussinesStatusCard } })
export default class BussinesDeck extends VueWizard {
  @Prop() readonly EventBus!: Vue;
  @Prop() readonly bussinesList!: any;
}
